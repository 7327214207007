import ReactOnRails from 'react-on-rails';
import LocalTime from "local-time"
import NavBar from '../bundles/layout/NavBar';
import CopyWidget from '../bundles/widgets/CopyWidget';
import PasteWidget from '../bundles/widgets/PasteWidget';
import mixpanel from "mixpanel-browser";

window.mixpanelToken = document.querySelector('meta[name="mixpanel"]').getAttribute('content');
mixpanel.init(window.mixpanelToken);

ReactOnRails.register({
  NavBar,
  CopyWidget,
  PasteWidget,
});
LocalTime.start();

import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

$(function () {
  const trackableLinks = $('.trackable-link');
  $(trackableLinks).on("click", function (e) {
    const linkText = e.currentTarget.dataset.linkText;
    const text = e.currentTarget.text;
    mixpanel.track(`Click link "${linkText || text}"`);
  });
});