import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

export default function CopyWidget(props) {
  const { copyText } = props;
  const [isCopied, setIsCopied] = useState(false);
  const [consultID, setConsultID] = useState();

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500); // Reset after 1.5 seconds
  };

  const onChangeConsultID = (event) => {
    setConsultID(event.target.value)
  };

  const onPasteClick = () => {
    navigator.clipboard
      .readText()
      .then((clipText) => {
        const numbers = findNumbers(clipText);
        if (numbers.length != 0) {
          const number = numbers[0]
          setConsultID(number)
          window.location.assign(`survey_results/${number}?debug=true`);
        }
      });
  };

  const onGoClick = () => {
    const numbers = findNumbers(consultID);
    if (numbers.length != 0) {
      const number = numbers[0]
      setConsultID(number)
      window.location.assign(`survey_results/${number}?debug=true`);
    }
  };

  function findNumbers(str) {
    const numbers = str.match(/\d+/g);
    return numbers ? numbers : [];
  }

  return (
    <div className="flex flex-row justify-center">
      <div className="flex flex-col w-32">
        ConsultID:
        <input value={consultID || ''} onChange={onChangeConsultID} type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg p-1" />
        <button className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-2" onClick={onPasteClick}>
          Paste & Go
        </button>
        <button className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-2" onClick={onGoClick}>
          Go
        </button>
      </div>
    </div>
  );
}